import { useEffect, useContext } from 'react';
import { useLoaderData, useRouteLoaderData, useParams } from 'react-router-dom';
import ProductSlider from '../selector/ProductSlider';
import ProductList from './ProductList';
import { useGlobalState } from "../configurator/State";
import { ModalContext } from '../context/ModalProvider';

export default function ProductSelector() {
    const products = useLoaderData();
    const { seriesSlug } = useParams();
    const { productSlug } = useParams();
    const [navMode] = useGlobalState("NavigationMode");
    const modal = useContext(ModalContext);

    const seriesName = seriesSlug?.substring(seriesSlug.indexOf('-') + 1) + (seriesSlug?.indexOf('Series') > 0 ? '' : ' Series');
    const productName = productSlug?.substring(productSlug.indexOf('-') + 1);

    const config = useRouteLoaderData("root");

    let selectedSlider = null;

    
    if (productName) {
        selectedSlider = config.banners.find(slider => (slider.keyValue == productName || slider.keyValue == productName.replace(' Series', '')));
    }
    if (!selectedSlider && seriesName) {
        selectedSlider = config.banners.find(slider => (slider.keyValue == seriesName || slider.keyValue == seriesName.replace(' Series', '')));
    }

    const output = (
        <div id="product_groups_container" className="productGroups contentContainer active">
            {(selectedSlider) ? (
                <ProductSlider slider={selectedSlider} />
            ) : (seriesSlug) ? (
                <>
                    <h1>{seriesName}</h1>
                    <p className="subheadline">{seriesSlug.includes('Series') ? 'Choose a Group' : 'Choose a Series'}</p>
                </>
            ) : (productSlug) ? (
                <>
                    <h1>{productName}</h1>
                    <p className="subheadline">Choose a Series</p>
                </>
            ) : (
                <>
                    <h1>Build My Kubota</h1>
                    <p className="subheadline">Start By Choosing a Product to Begin</p>
                </>
            )}

            <ProductList products={products.ProductGroups} />

        </div>
    );

    useEffect(() => {
        if (seriesSlug || productSlug) {
            document.title = "Kubota Tractor - Build My Kubota - " + (seriesSlug ? seriesName : productName);
        }
        else {
            document.title = "Kubota Tractor - Build My Kubota";
        }

        if (navMode === "landpride") {
            modal.show((
                <div class="container text-center">{output}</div>
            ));
        }
    }, [seriesSlug, productSlug]);

    if (navMode === "landpride") {
        return <div className="landpride-loading">Loading...</div>;
    }
    else {
        return output;
    }
    
}
