import { useState, useContext, useEffect } from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { ModalContext } from '../context/ModalProvider';
import { useGlobalState } from "../configurator/State";

export default function Product({ product }) {
    const [showPackageSelector, setPackageSelector] = useState(false);
    const config = useRouteLoaderData("root");

    const [minPrice, setMinPrice] = useState(config.calcPrices?.includes(product.ParentGroupId) ? null : product.MinMSRP);
    const hp = product.HP ? product.HP : null;

    const calcPrices = async () => {
        const msrps = [];

        await Promise.all(product.BMKGroups.map(async bmkGroup => {
            await Promise.all(bmkGroup.ProductList.map(async tractor => {
                const productResponse = await fetch('/inventory/bmktractor?id=' + tractor.Name.trim());
                const productJson = await productResponse.json();

                let addOnMsrp = productJson.Tractor.DefaultTires.MsrpPrice;
                productJson.Tractor.IncludedItems.forEach(item => {
                    addOnMsrp += item.MsrpPrice;
                });

                tractor.MsrpPrice = productJson.Tractor.MSRPWithoutTires + addOnMsrp;
                msrps.push(tractor.MsrpPrice);
            }));
        }));

        setMinPrice(Math.min(...msrps));
    }

    useEffect(() => {
        if (config.calcPrices?.includes(product.ParentGroupId)) {
            calcPrices();
        }
    }, []);

    
    const searchRegExp = /[\/]/g;
    const replaceWith = ' / ';

    return (
        <div className="modelContainer eligible">
            <span className="groupThumbContainer">
                <img src={product.ImageUrl} className="groupThumb thumb" alt={product.Name} />
            </span>
            <span className="modelHeadline">Model</span>
            <span className="modelNameHeadline">{product.Name.replace(searchRegExp, replaceWith)}</span>
            <span className="modelDescription">{product.Description}</span>
            <span className="modelHPMsrpContainer">
            {hp &&
                <span className="hp-container centerIfOnly">
                    <span className="hp-value">{hp}</span>
                    <span className="hp-label">HP</span>
                </span>
            }
            {minPrice && 
                <span className="msrp-container centerIfOnly">
                        <span className="msrp-value">{minPrice ? '$' + minPrice.toLocaleString() : ''}</span>
                    <span className="msrp-label">MSRP As Low As</span>
                </span>
            }
            </span>
            {
                (product.ParentGroupId === 124 && !minPrice) ? (
                    <a className="button orange-button choosePackage eligible">Loading...</a>
                ) : (
                    <a className="button orange-button choosePackage eligible" onClick={() => (setPackageSelector(true))}>Choose a Package</a>
                )
            }
            {showPackageSelector &&
                <PackageList product={product} closeHandler={() => (setPackageSelector(false))} />
            }
        </div>
    );
}


function PackageList({ product, closeHandler }) {
    const modal = useContext(ModalContext);
    const [navMode] = useGlobalState("NavigationMode");
    const [selectedPackage, setPackage] = useState();
    const [openGroups, setOpenGroups] = useState([]);

    function toggleOpenGroup(name) {
        if (openGroups.includes(name)) {
            setOpenGroups(openGroups.filter(i => i != name));
        }
        else {
            setOpenGroups([...openGroups, name]);
        }
    }

    const sendEvent = () => {
        modal.show();
        window.gtag('event', 'Model Selected', {
            'Series': product.Name
        });
    }

    return (
        <div className="modelPicker">
            <a className="unPick" onClick={closeHandler}><em className="bi bi-x"></em></a>
            <div className="modelPickerHeader">Base Model Picker</div>
            <div className="modelPickerDisclaimer">Please select a package to get started</div>
            <div className="modelPickerItemsContainer">
                {product.BMKGroups?.length > 0 ? (
                    <>
                        {product.BMKGroups.map(packageGroup => {
                            let groupName = packageGroup.Name ? packageGroup.Name : "Package Group";
                            return (
                                <div key={packageGroup.Id} className={"modelPickerGroup" + (openGroups.includes(groupName) ? " open" : "")}>
                                    <h3 onClick={() => toggleOpenGroup(groupName)}>{groupName}<em className={openGroups.includes(groupName) ? "bi bi-chevron-up" : "bi bi-chevron-down"}></em></h3>
                                    {openGroups.includes(groupName) && packageGroup.ProductList.map(productPackage => (
                                        <ProductPackage key={productPackage.Id}
                                            productPackage={productPackage}
                                            active={productPackage.Name == selectedPackage}
                                            clickHandler={() => setPackage(productPackage.Name)}
                                        />
                                    ))}
                                </div>
                            )
                        })}
                        {product.ProductList?.length > 0 && (
                            <div className={"modelPickerGroup" + (openGroups.includes("other") ? " open" : "")}>
                                <h3 onClick={() => toggleOpenGroup("other")}>Other<em className={openGroups.includes("other") ? "bi bi-chevron-up" : "bi bi-chevron-down"}></em></h3>
                                {openGroups.includes("other") && product.ProductList.map(productPackage => (
                                    <ProductPackage key={productPackage.Id}
                                        productPackage={productPackage}
                                        active={productPackage.Name == selectedPackage}
                                        clickHandler={() => setPackage(productPackage.Name)}
                                    />
                                ))}
                            </div>
                        )}
                    </>

                ):(
                    product.ProductList.map(productPackage => (
                        <ProductPackage key={productPackage.Id}
                            productPackage={productPackage}
                            active={productPackage.Name == selectedPackage}
                            clickHandler={() => setPackage(productPackage.Name)}
                        />
                    ))
                )}

            </div>
            {selectedPackage ? (
                <Link to={encodeURIComponent(selectedPackage.trim()) + (navMode === "landpride" ? "/land-pride/" : "")} relative="path" onClick={sendEvent} className="startConfiguring orange button orange-button eligible">Start Configuring</Link>

            ):(
                <div className="startConfiguring orange button orange-button eligible disabled">Start Configuring</div>
            )}
        </div>
    );
}


function ProductPackage({ productPackage, active, clickHandler }) {
    const displayPrice = productPackage.MsrpPrice ? '$' + productPackage.MsrpPrice.toLocaleString() : 'Call for Pricing';
    const packageDetails = productPackage.Description ? productPackage.Description : productPackage.Name;

    return (
        <div className="modelPickerItem">
            <a className="modelPickerRadioContainer chooseCurrentModel" onClick={clickHandler}>
                <em className={active ? "bi bi-check-circle-fill orange" : "bi bi-circle"}></em>
            </a>
            <div className="modelPickerContentContainer">
                <span className="modelPickerContentDescription">{packageDetails}</span>
                <span className="modelPickerContentCost">{displayPrice}</span>
            </div>
        </div>
    );
}
